import React from "react"

const SchoolTextSection = props => {
  const title = props.title
  const text = props.text
  const arr =
    text && text.length ? text.split("\n").filter(el => el && el.length) : []
  return arr.length ? (
    <div className="row info-bordered-wrapper">
      <div className="col-lg-2"></div>
      <div className="col about-history-block about-school-info">
        {title && (
          <div className="row">
            <div className="key-data-title school-info-title col-12">
              {title}
            </div>
          </div>
        )}
        <div className="row">
          <div className="info-text-wrapper col-12">
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </div>
      <div className="col-lg-1"></div>
    </div>
  ) : (
    <span></span>
  )
}

export default SchoolTextSection
