import React, { useState } from "react"
import SchoolTextSection from "./SchoolTextSection"
import { useTranslation } from "react-i18next"
import { addSpaceNumber } from "../../scripts/Utils"

const SchoolItemFeesSection = props => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const fees = props.fees ? props.fees : []
  const activeIndexSelect = props.fees.findIndex(item=> item?.isActive)
  const [activeIndex, setActiveIndex] = useState(activeIndexSelect)
  const [activeFee, setActiveFee] = useState(fees.find(item => item?.isActive))

  const onSetActiveFee = idx => {
    setActiveIndex(idx)
    if(fees.length){
      setActiveFee(fees[idx])
    }
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthNamesUa = [
    "Січня",
    "Лютого",
    "Березня",
    "Квітня",
    "Травня",
    "Червня",
    "Липня",
    "Серпня",
    "Вересня",
    "Жовтня",
    "Листопада",
    "Грудня",
  ]
  const monthNamesRu = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ]

  const getActiveClass = isActive => {
    return isActive ? "active" : ""
  }

  function dateName(item) {
    if (lang === "en") {
      return (
        new Date(item).getDate() + " " + monthNames[new Date(item).getMonth()]
      )
    }
    if (lang === "uk") {
      return (
        new Date(item).getDate() +
        " " +
        monthNamesUa[new Date(item).getMonth()].toLowerCase()
      )
    }
    if (lang === "ru") {
      return (
        new Date(item).getDate() +
        " " +
        monthNamesRu[new Date(item).getMonth()].toLowerCase()
      )
    }
  }
  const resolveDeposit = deposit => {
    if (
      deposit.depositMin !== deposit.depositMax &&
      deposit.depositMin != null &&
      deposit.depositMax != null
    ) {
      return (
        <div className="key-data-item-text">
          £{addSpaceNumber(deposit.depositMin)}–{addSpaceNumber(deposit.depositMax)}
        </div>
      )
    } else {
      return (
        <div className="key-data-item-text">
          £{addSpaceNumber(deposit.depositMin || deposit.depositMax)}
        </div>
      )
    }
  }

  function resolveFeeGroups(fee) {
    if (fee && fee.feeGroups && fee.feeGroups[0].feePerTermAmount) {
      return (
        <div className="key-data-item key-item2">
          <div className="key-data-item-title">{t("school.fees.fullFee")}</div>
          {(fee.feeGroups || []).map(group =>
            group.feePerTermAmount ? (
              <div key={group.id} className="key-data-item-text">
                £{addSpaceNumber(group.feePerTermAmount)}
              </div>
            ) : null
          )}
        </div>
      )
    } else {
      return null
    }
  }

  function resolveSummerSchoolFeeCourses(summerFee) {
    if (
      summerFee.summerSchoolFeeCourses &&
      summerFee.summerSchoolFeeCourses[0]?.startDate
    ) {
      return (
        <div className="key-data-item key-item2 col-sm-3 col-md-3 col-lg-3">
          <div className="key-data-item-title">{t("school.fees.dates")}</div>
          {(summerFee.summerSchoolFeeCourses || []).map(group =>
            group.endDate && group.startDate ? (
              <div key={group.id} className="key-data-item-text">
                {dateName(group.startDate)}–{dateName(group.endDate)}{" "}
              </div>
            ) : null
          )}
        </div>
      )
    } else {
      return null
    }
  }

  const resolveFeePerTerm = item => {
    if (item.perTermMin || item.perTermMax) {
      return (
        <div className="key-data-item key-item2 col-sm-4 col-md-4 col-lg-4">
          <div className="key-data-item-title">
            {t("school.fees.feesPerTerm")}
          </div>
          {item.perTermMin !== item.perTermMax &&
          item.perTermMin != null &&
          item.perTermMax != null ? (
            <div className="key-data-item-text">
              £{addSpaceNumber(item.perTermMin)}–{addSpaceNumber(item.perTermMax)}
            </div>
          ) : (
            <div className="key-data-item-text">
              £{addSpaceNumber(item.perTermMin || item.perTermMax)}
            </div>
          )}
        </div>
      )
    } else {
      return (
            <div className="key-data-item f-js key-item1 col-sm-4 col-md-4 col-lg-4">
              {(item.weeklyFeeMin || item.weeklyFeeMax) ? <div><div className="key-data-item-title">
                {t("school.fees.feesWeekly")}
              </div>
                <div className="key-data-item-text">
                  £{addSpaceNumber(item.weeklyFeeMin)}–{addSpaceNumber(item.weeklyFeeMax)}
                </div> </div> : null}
          </div>


      )
    }
  }

  const resolveRegistrationsFees = regFee => {
    if (regFee.registrationFee) {
      return (
        <div className="key-data-item key-item2 col-sm-4 col-md-4 col-lg-4">
          <div className="key-data-item-title">{t("school.fees.regFee")}</div>
          <div className="key-data-item-text">
            £{addSpaceNumber(regFee.registrationFee)}
          </div>
        </div>
      )
    } else {
      return null
    }
  }


  const resolveSummerSchoolsFeesCourses = sumSchool => {
    if (sumSchool.summerSchoolFeeCourses.length && sumSchool.summerSchoolFeeCourses[0]?.courseName) {
      return (
        <div className="key-data-item f-js key-item1 col-sm-3 col-md-3 col-lg-3">
          {sumSchool.summerSchoolFeeCourses[0]?.courseName && (
            <div>
              <div className="key-data-item-title">
                {t("school.fees.courseName")}
              </div>
              {(sumSchool.summerSchoolFeeCourses || []).map(group => (
                <div key={group.id} className="key-data-item-text">
                  {group.courseName}
                </div>
              ))}
            </div>
          )}
        </div>
      )
    } else {
      return (
        <>
          {sumSchool.feeGroups && sumSchool.feeGroups[0]?.yearGroup > 0 && (
            <div className="key-data-item f-js key-item1">
              <div className="key-data-item-title">
                {t("school.fees.yearGroup")}
              </div>
              {(sumSchool.feeGroups || []).map(group => (
                <div key={group.id} className="key-data-item-text">
                  {group.yearGroup}
                </div>
              ))}
            </div>
          )}
        </>
      )
    }
  }

  const resolveSummerSchoolFeesDescriptions = summerSchool => {
    if (summerSchool.summerSchoolFeeCourses && summerSchool.summerSchoolFeeCourses[0].description) {
      return (
        <>
          {summerSchool.summerSchoolFeeCourses.length && summerSchool.summerSchoolFeeCourses[0].description && (
            <div className="key-data-item f-js key-item1 col-sm-3 col-md-3 col-lg-3">
              <div className="key-data-item-title">
                {t("school.fees.description")}
              </div>
              {(summerSchool.summerSchoolFeeCourses || []).map(group => (
                <div key={group.id} className="key-data-item-text">
                  {group.description}
                </div>
              ))}
            </div>
          )}
        </>
      )
    } else {
      return null
    }
  }

  const resolveSummerSchoolAnotherFee = theSummerFee => {
    if (theSummerFee.summerSchoolFeeCourses && theSummerFee.summerSchoolFeeCourses[0]?.fees) {
      return (
        <div className="key-data-item key-item2 col-sm-3 col-md-3 col-lg-3">
          {theSummerFee.summerSchoolFeeCourses[0]?.fees && (
            <div>
              <div className="key-data-item-title">{t("school.fees.fees")}</div>

              {(theSummerFee.summerSchoolFeeCourses || []).map(group =>
                group.fees ? (
                  <div key={group.id} className="key-data-item-text">
                    {group.fees}
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>
      )
    } else {
      return resolveFeeGroups(theSummerFee)
    }
  }


  return activeFee ? (
    <div className="white-block about-school-block">
      <div className="about-school-content-wrapper container f-js">
        {fees && fees.length && (
          <div className="row">
            <div className="col-lg-2" />
            <div
              className={`about-school-info col ${
                activeFee.summerSchoolFeeCourses ||
                activeFee.hasOwnProperty("summerSchoolFeeCourses") ||
                activeFee.feesContent == null
                  ? ""
                  : "fees-info-wrapper pb-6"
              }`}
            >
              <div className="years-list f-ac">
                {fees.map((f, idx) => {
                  const isActive = activeIndex === idx
                  return (
                    <div key={f.id}>
                      {f.year ? (
                        <div
                          onClick={() => onSetActiveFee(idx)}
                          style={{ cursor: "pointer" }}
                          className={`key-data-title school-info-title years-item ${getActiveClass(
                            isActive
                          )}`}
                        >
                          {f?.year}{" "}
                        </div>
                      ) : (
                        <div
                          onClick={() => onSetActiveFee(idx)}
                          style={{ cursor: "pointer" }}
                          className={`key-data-title school-info-title years-item ${getActiveClass(
                            isActive
                          )}`}
                        >
                          {f?.year}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className="key-data-info-list row">
                {resolveFeePerTerm(activeFee)}

                {resolveRegistrationsFees(activeFee)}


                {(activeFee.depositMin || activeFee.depositMax) ?
                  <div className="key-data-item key-item3 col-sm-4 col-md-4 col-lg-4">
                    <div className="key-data-item-title">
                      {t("school.fees.deposit")}
                    </div>
                    {resolveDeposit(activeFee)}
                  </div>
                  : null}

              </div>
            </div>
            <div className="col-lg-1"/>
          </div>
        )}

        {activeFee.summerSchoolFeeCourses && activeFee.summerSchoolFeeCourses.length ? (
          <div className="row ">
            <div className="col-lg-2" />
            <div className="about-school-info col summer-fees-info-wrapper">
              <div className="key-data-info-list year-groups row justify-content-star">
                { resolveSummerSchoolsFeesCourses(activeFee)}

                {resolveSummerSchoolFeesDescriptions(activeFee)}

                { resolveSummerSchoolAnotherFee(activeFee)}

                { resolveSummerSchoolFeeCourses(activeFee)}
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        ) : null}

        <SchoolTextSection
          title={t("school.fees.feeIncludes")}
          text={activeFee.feesContent}
        />
        <SchoolTextSection
          title={t("school.fees.discounts")}
          text={activeFee.discounts}
        />
        <SchoolTextSection
          title={t("school.fees.languageTuition")}
          text={activeFee.teachingEnglish}
        />
        <SchoolTextSection
          title={t("school.fees.otherFees")}
          text={activeFee.feesOther}
        />
      </div>
    </div>
  ) : <div className="mb-13"/>
}

export default SchoolItemFeesSection
